<template>
  <div :class="{ sidebar: true, 'menu-collapsed': $store.state.isCollapse, 'menu-expanded': !$store.state.isCollapse }">
    <div class="manager_name" @click="clickLogo">
      <img v-if="!$store.state.isCollapse" src="/img/logo/logo-yun.png" class="home_img" />
      <img v-else src="/img/logo/logo.png" style="width: 36px; height: 36px" class="home_img" />
    </div>
    <el-scrollbar style="flex: 1" wrap-style="overflow-x:hidden;">
      <!--导航菜单-->
      <el-menu
        background-color="#304156"
        class="el-menu-vertical-demo"
        style="overflow-y: hidden"
        text-color="rgb(230, 230, 230)"
        active-text-color="rgb(64, 158, 255)"
        :default-active="activeMenu"
        unique-opened
        @select="handleSelect"
        :collapse="$store.state.isCollapse"
      >
        <template v-for="item of routers">
          <el-submenu v-if="!item.hidden && item.children" :key="item.meta.title" :index="item.meta.title">
            <template slot="title">
              <svg-icon v-if="item.meta.icon && item.meta.icon != '#'" :icon-class="item.meta.icon" class="menu-icon"></svg-icon>
              <span>{{ item.meta.title }}</span>
              <el-badge
                is-dot
                class="badge_first"
                v-if="
                  (msgCount.abnormalOrderCount > 0 && item.meta.title == '统计管理') ||
                  ((msgCount.faultDeviceCount > 0 || msgCount.offlineDeviceCount > 0) && item.meta.title == '设备管理') ||
                  (msgCount.withdrawAccountApplyCount > 0 && item.meta.title == '个人中心') ||
                  (approveNum > 0 && item.meta.title == '商品管理')
                "
              ></el-badge>
            </template>
            <template v-for="child of item.children">
              <el-submenu v-if="!child.hidden && child.children" :key="child.meta.title" :index="child.meta.title">
                <template slot="title">
                  <svg-icon v-if="child.meta.icon && child.meta.icon != '#'" :icon-class="child.meta.icon" class="menu-icon"></svg-icon>
                  <span>{{ child.meta.title }}</span>
                  <el-badge
                    is-dot
                    class="badge_first"
                    v-if="
                      (msgCount.abnormalOrderCount > 0 && item.meta.title == '统计管理') ||
                      ((msgCount.faultDeviceCount > 0 || msgCount.offlineDeviceCount > 0) && item.meta.title == '设备管理') ||
                      (msgCount.withdrawAccountApplyCount > 0 && item.meta.title == '个人中心') ||
                      (approveNum > 0 && item.meta.title == '商品管理')
                    "
                  ></el-badge>
                </template>
                <template v-for="grandchild of child.children">
                  <el-menu-item
                    v-if="!grandchild.hidden"
                    :index="grandchild.path"
                    :key="grandchild.path"
                    :route="grandchild"
                    @click="clickMenu(grandchild)"
                  >
                    <svg-icon
                      v-if="grandchild.meta.icon && grandchild.meta.icon != '#'"
                      :icon-class="grandchild.meta.icon"
                      class="menu-icon"
                    ></svg-icon>
                    <template slot="title">
                      <span>{{ grandchild.meta.title }}</span>
                      <el-badge
                        class="badge_second"
                        :value="msgCount.abnormalOrderCount"
                        :max="99"
                        v-if="msgCount.abnormalOrderCount > 0 && grandchild.meta.title == '订单统计'"
                      >
                      </el-badge>
                      <el-badge
                        class="badge_second"
                        :value="msgCount.faultDeviceCount"
                        :max="99"
                        v-if="msgCount.faultDeviceCount > 0 && grandchild.meta.title == '设备故障'"
                      >
                      </el-badge>
                      <el-badge
                        class="badge_second"
                        :value="msgCount.offlineDeviceCount"
                        :max="99"
                        v-if="msgCount.offlineDeviceCount > 0 && grandchild.meta.title == '设备列表' && grandchild.path == '/equipment_list'"
                      >
                      </el-badge>
                      <el-badge
                        class="badge_second"
                        :value="msgCount.withdrawAccountApplyCount"
                        :max="99"
                        v-if="msgCount.withdrawAccountApplyCount > 0 && grandchild.meta.title == '商户资金'"
                      >
                      </el-badge>
                      <el-badge class="badge_second" :value="approveNum" :max="99" v-if="approveNum > 0 && grandchild.meta.title == '商品审核'">
                      </el-badge>
                    </template>
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item v-else-if="!child.hidden && !child.children" :index="child.path" :key="child.path" :route="child">
                <svg-icon v-if="child.meta.icon && child.meta.icon != '#'" :icon-class="child.meta.icon" class="menu-icon"></svg-icon>
                <template slot="title">
                  <span>{{ child.meta.title }}</span>
                  <el-badge
                    class="badge_second"
                    :value="msgCount.abnormalOrderCount"
                    :max="99"
                    v-if="msgCount.abnormalOrderCount > 0 && child.meta.title == '订单统计'"
                  >
                  </el-badge>
                  <el-badge
                    class="badge_second"
                    :value="msgCount.faultDeviceCount"
                    :max="99"
                    v-if="msgCount.faultDeviceCount > 0 && child.meta.title == '设备故障'"
                  >
                  </el-badge>
                  <el-badge
                    class="badge_second"
                    :value="msgCount.offlineDeviceCount"
                    :max="99"
                    v-if="msgCount.offlineDeviceCount > 0 && child.meta.title == '设备列表' && child.path == '/equipment_list'"
                  >
                  </el-badge>
                  <el-badge
                    class="badge_second"
                    :value="msgCount.withdrawAccountApplyCount"
                    :max="99"
                    v-if="msgCount.withdrawAccountApplyCount > 0 && child.meta.title == '商户资金'"
                  >
                  </el-badge>
                  <el-badge class="badge_second" :value="approveNum" :max="99" v-if="approveNum > 0 && child.meta.title == '商品审核'"> </el-badge>
                </template>
              </el-menu-item>
            </template>
          </el-submenu>
          <el-menu-item v-else-if="!item.hidden && !item.children" :index="item.path" :key="item.path" :route="item">
            <svg-icon v-if="item.meta.icon && item.meta.icon != '#'" :icon-class="item.meta.icon" class="menu-icon"></svg-icon>
            <template slot="title">
              <span>{{ item.meta.title }}</span>
              <el-badge
                class="badge_second"
                :value="msgCount.abnormalOrderCount"
                :max="99"
                v-if="msgCount.abnormalOrderCount > 0 && item.meta.title == '订单统计'"
              >
              </el-badge>
              <el-badge
                class="badge_second"
                :value="msgCount.faultDeviceCount"
                :max="99"
                v-if="msgCount.faultDeviceCount > 0 && item.meta.title == '设备故障'"
              >
              </el-badge>
              <el-badge
                class="badge_second"
                :value="msgCount.offlineDeviceCount"
                :max="99"
                v-if="msgCount.offlineDeviceCount > 0 && item.meta.title == '设备列表' && item.path == '/equipment_list'"
              >
              </el-badge>
              <el-badge
                class="badge_second"
                :value="msgCount.withdrawAccountApplyCount"
                :max="99"
                v-if="msgCount.withdrawAccountApplyCount > 0 && item.meta.title == '商户资金'"
              >
              </el-badge>
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { EventBus } from "@/event";
import store from "../store";
import NProgress from "nprogress";
// import {mapGetters} from "vuex";

export default {
  name: "AppLeft",
  // props: ["franchiseeAll"],
  data() {
    return {
      activeMenu: "",
      isCollapse: store.state.isCollapse,
      lastReqTime: new Date(),
      msgCount: {}
    };
  },
  watch: {
    "$route.fullPath"(to, from) {
      this.onRouteChanged();
    }
  },
  components: {},
  computed: {
    // ...mapGetters(["routers"]),
    //所有的路由信息
    routers() {
      this.updateActiveMenu();
      return this.$store.getters.routers;
    },
    franchiseeAll: {
      get() {
        return this.$store.state.franchiseeList;
      }
    },
    approveNum() {
      if (this.isMain) {
        return this.msgCount.pendingApproveCount;
      } else {
        return this.$store.getters.approveNum;
      }
    }
  },
  methods: {
    // getApproveNum() {
    //   const resObj = this.franchiseeAll.find((item) => item.franchiseeId == this.util.getCookie("franchisee_id"));
    //   //console.log(this.$store.state.franchiseeList, decodeURIComponent(this.util.getCookie("franchisee_id")), resObj, "11111111111111");
    //   if (resObj) {
    //     this.approveNum1 = resObj.approveNum;
    //   }
    // },
    clickLogo() {
      this.$store.commit("updateIsCollapse", !this.$store.state.isCollapse);
    },
    clickMenu(val) {
      // console.log(val);
      // this.$store.dispatch('commitTag', val)
      // this.$store.commit("pushtags",val);
      // this.$store.commit('setCacheView',val)
    },
    onRouteChanged() {
      this.getNewMsg();
      this.updateActiveMenu();
    },
    handleSelect(key, keyPath) {
      // this.activeMenu = key;
      if (this.isHttp(key)) {
        if (key.includes("{auth_code}")) {
          NProgress.start();
          this.post("authority/create_auth_code").then(
            (res) => {
              NProgress.done();
              if (res.errorCode == 0) {
                key = key.replace("{auth_code}", res.data.code);
                window.open(key, "_blank");
              }
            },
            () => {
              NProgress.done();
            }
          );
        } else if (key.includes("{host}")) {
          let host = window.location.host;
          key = key.replace("{host}", host);
          window.open(key, "_blank");
        } else {
          // http(s):// 路径新窗口打开
          window.open(key, "_blank");
        }
      } else if (key.indexOf("/redirect") !== -1) {
        // /redirect 路径内部打开
        this.$router.push({ path: key.replace("/redirect", "") });
      } else {
        // 显示左侧联动菜单
        this.activeRoutes(key);
      }
      this.getNewMsg();
    },
    // changeCollapse() {
    //   this.$store.state.isCollapse = !this.$store.state.isCollapse;
    // },
    // pushState(res){
    //   console.error('==============',res)
    // },
    initNotify() {
      let notify = window.Notification || window.webkitNotifications;
      if (notify) {
        if (notify.permission == "granted") {
          console.debug("Notification is granted");
        } else if (Notification.permission !== "denied") {
          notify.requestPermission(function (permission) {
            if (!("permission" in Notification)) {
              Notification.permission = permission;
            }
            if (permission === "granted") {
              let notification = new Notification("有新消息时将在此显示通知提醒");
            }
          });
        } else {
          console.warn("Notification is denied");
        }
      } else {
        console.debug("Notification is not supported");
      }
    },
    getNewMsgOnce() {
      if (!this.isAdmin && !this.isMain) return;
      this.lastReqTime = new Date();
      const that = this;
      this.get("franchiseeMessages/statistics")
        .then((res) => {
          if (res.errorCode == 0) {
            that.msgCount = res.data;
          } else {
            that.$message.error(res.message);
          }
          EventBus.$emit("processUpdatingMessage", res.data.systemUpdatingVo);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getNewMsg() {
      const seconds = (new Date() - this.lastReqTime) / 1000;
      if (seconds < 120) {
        return false;
      }
      this.getNewMsgOnce();
    },
    /**
     * 获取前一个节点
     */
    getPreMenuPath(routers) {
      for (let index in routers) {
        let router = routers[index];
        if (router.path == this.$route.path) {
          while (index-- > 0) {
            if (!routers[index].meta.hidden && routers[index].path) {
              return routers[index].path;
            }
          }
          return null;
        }
        if (router.children) {
          let path = this.getPreMenuPath(router.children);
          if (path) {
            return path;
          }
        }
      }
      return null;
    },
    updateActiveMenu() {
      if (this.$route.meta.hidden) {
        if (this.$route.matched) {
          //存在父节点，取父节点menu
          let index = this.$route.matched.length - 1;
          while (index > 0) {
            index--;
            if (!this.$route.matched[index].meta.hidden && this.$route.matched[index].path) {
              this.activeMenu = this.$route.matched[index].path;
              return;
            }
          }
        }
        //取前一个可见菜单
        let path = this.getPreMenuPath(this.$store.getters.routers);
        if (path) {
          this.activeMenu = path;
        }
      } else {
        let keys = this.$route.path.split("/");
        if (keys.length > 1) {
          this.activeMenu = "/" + keys[keys.length - 1];
        }
      }
    },
    // 当前激活的路由
    activeRoutes(key) {
      let routes = [];
      this.routers.map((item) => {
        if (key == item.url && item.child) {
          //如果选中导航的key值与遍历项的url匹配并且有子级，那么就将该项的子级数组放在routes中
          routes = item.child;
        } else if (key == item.url && !item.child) {
          //只满足选中导航的key值与遍历项的url匹配但是没有子级的情况下，就把这一项赋值给vuex中
          //这一项其实针对控制台，控制台没有子级
          // this.$store.commit("permission/SET_SIDEBAR_ROUTERS", [item]);
          this.$store.sidebarRouters = [item];
        }
      });
      if (routes.length > 0) {
        //routes数组有长度就将它放在vuex中，左侧导航就能读取到，展示的也是选中项的子级
        // this.$store.commit("permission/SET_SIDEBAR_ROUTERS", routes);
        this.$store.sidebarRouters = routes;
      }
      if (this.$route.path != key) {
        this.$router.push(key);
      }
      return routes;
    },
    isHttp(url) {
      return url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1;
    }
  },

  created() {
    // if (this.franchiseeAll.length) {
    //this.getApproveNum();
    // }
    EventBus.$on("getNewMsg", () => {
      // this.getApproveNum();
    });
    EventBus.$on("newGetApproveNum", () => {
      this.getNewMsgOnce();
    });
  },
  activated() {
    this.onRouteChanged();
  },
  mounted() {
    if (window.screen.width < 768) {
      this.$store.commit("updateIsCollapse", true);
    }
    this.updateActiveMenu();
    this.getNewMsgOnce();
    EventBus.$on("getNewMsg", (clearFirst) => {
      if (clearFirst) {
        this.msgCount = {};
      }
      this.getNewMsgOnce();
    });
    this.initNotify();

    // if (window.history && window.history.pushState) {
    //     history.pushState(null, null, document.URL);
    //     window.addEventListener('popstate', this.pushState, false);
    // }
  },
  beforeDestroy() {
    EventBus.$off("getNewMsg");
  }
};
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.menu-collapsed .collapsed .el-submenu .item {
  position: relative;
}

.menu-collapsed .collapsed .submenu {
  position: absolute;
  top: 0px;
  left: 65px;
  z-index: 99999;
  height: auto;
  display: block;
}

.sidebar {
  position: fixed;
  background-color: #304156;
  z-index: 100;
  height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 2px 0 10px rgba(0, 21, 41, 0.3);
  box-shadow: 2px 0 10px rgba(0, 21, 41, 0.3);
}

.menu-collapsed {
  width: 65px;
  transition: width 0.4s;
}

.menu-expanded {
  width: 200px;
  transition: width 0.4s;
}

/deep/ .badge_first > sup {
  border: 0;
  margin-bottom: 20px;
}

/deep/ .badge_second > sup {
  border: 0;
  margin-bottom: 5px;
}

.menu-icon {
  width: 14px;
  height: 15px;
  margin-right: 10px;
  color: #3a8ee6;
}

.scrollbar-wrapper {
  overflow-x: hidden !important;
}
</style>
<style lang="scss" scoped>
.el-submenu .el-menu-item {
  background-color: #263445 !important;

  &:hover {
    background-color: #0b2942 !important;
  }
}
</style>
