<template>
  <div class="content_center">
    <el-result icon="info" title="页面未找到或无权访问">
      <template slot="extra">
        <router-link to="/" replace style="color: #3a8ee6; font-size: 16px" v-if="$store.state.homePath">返回首页</router-link>
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>
<style scoped>
.content_center {
  margin-top: 100px;
  text-align: center;
}
</style>
