import Vue from "vue";
import Vuex from "vuex";
import tagsView from "./store/modules/tagsView";
import { getCookie } from "./util.js";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isCollapse: false,
    fixedHeader: true,
    screenWidth: 1280,
    tags: [], //tags数组
    cacheView: [], // 保存需要缓存的数组
    isShow: false, //tagsview标签显示隐藏,
    homePath: "",
    userInfo: {},
    routers: [],
    permissions: [],
    partnerList: [],
    partnerId: null, //合作商id
    franchiseeList: [], //加盟商列表
    resFranchiseeList: [], //后端数据的加盟商列表,
    approveNum: 0
  },
  modules: {
    tagsView
  },
  getters: {
    getCacheView(state) {
      return state.cacheView;
    },
    homePath: (state) => state.homePath,
    userInfo: (state) => state.userInfo,
    routers: (state) => state.routers,
    permissions: (state) => state.permissions,
    partnerList: (state) => state.partnerList,
    partnerId: (state) => state.partnerId,
    hasMenu: (state) => {
      for (let route of state.routers) {
        // console.error(route)
        if (!route.hidden) return true;
      }
      return false;
    },
    visitedViews: (state) => state.tagsView.visitedViews,
    cachedViews: (state) => state.tagsView.cachedViews,
    franchiseeList: (state) => state.franchiseeList,
    approveNum: (state) => {
      return state.approveNum;
    }
  },
  mutations: {
    setPartnerList(state, data) {
      state.partnerList = data;
      if (!data.some((item) => item.partnerId == state.partnerId)) {
        state.partnerId = null;
      }
    },
    setPartnerId(state, data) {
      state.partnerId = data;
    },

    setFranchiseeList(state, data) {
      state.franchiseeList = data;
      const resObj = state.franchiseeList.find((item) => item.franchiseeId == getCookie("franchisee_id"));
      if (resObj) {
        state.approveNum = resObj.approveNum;
      }
    },
    setResFranchiseeList(state, data) {
      state.resFranchiseeList = data;
      const resObj = state.franchiseeList.find((item) => item.franchiseeId == getCookie("franchisee_id"));
      if (resObj) {
        state.approveNum = resObj.approveNum;
      }
    },

    clearUserInfo(state) {
      state.homePath = "";
      state.userInfo = {};
      state.routers = [];
      state.permissions = [];
      state.partnerList = [];
    },
    setCacheView(state, data) {
      // 与setToolData类似
      // console.log(data)
      if (state.cacheView.includes(data.name)) return;
      state.cacheView.push(data.name);
    },
    clearCacheView(state, data) {
      const index = state.cacheView.findIndex((item) => item == data.name);
      state.cacheView.splice(index, 1);
    },

    updateIsCollapse(state, value) {
      state.isCollapse = value;
    },
    updateFixedHeader(state, value) {
      state.fixedHeader = value;
    },
    updateScreenWidth(state, value) {
      state.screenWidth = value;
    },
    pushtags(state, val) {
      //如果等于-1说明tabs不存在那么插入，否则什么都不做
      //findindex找角标，循环判断一下，如果等于那么就代表有相同的，就不必添加，如果找不到那就是-1.就添加
      let result = state.tags.findIndex((item) => item.name === val.name);
      result === -1 ? state.tags.push(val) : "";
    },
    //关闭标签
    closeTab(state, val) {
      //同上，找角标，然后用角标的位置对应删除一位。splice：这是数组的删除方法
      let result = state.tags.findIndex((item) => item.name === val.name);
      state.tags.splice(result, 1);
    },
    //关闭所有tagsview标签
    cleartagsview(state, val) {
      //清空数组
      state.tags = [];
      //跳转到首页,val接受传过来的当前路由
      if (val !== "/") {
        router.push({ path: "/" });
      }
    },
    //改变tagsview显示隐藏
    changeisshow(state) {
      state.isShow = !state.isShow;
    }
  },
  actions: {
    commitTag({ commit }, data) {
      commit("pushtags", data);
      commit("setCacheView", data);
    },
    clearTag({ commit }, data) {
      commit("closeTab", data);
    },
    clearCache({ commit }, data) {
      commit("clearCacheView", data);
    }
  }
});
export default store;
