import Vue from "vue";
import Router from "vue-router";
import {get} from "./axios/api";
import store from "./store";
import * as util from "./util";
import {setCookie} from "./util";
import Login from "@/views/Login.vue";
import ForgetThePassword from "@/views/ForgetThePassword.vue";
import InnerLink from "./components/InnerLink";
import ParentView from "./components/ParentView";
import NotFound from "@/views/NotFound.vue";
import Layout from "./components/Main.vue";
import NProgress from "nprogress";

Vue.use(Router);

const whiteList = ["/login", "/forget_the_password", "/not_found"];
const router = new Router({
  hashbang: false,
  history: true,
  mode: "history",
  routes: [
    { path: "/login", name: "login", component: Login, hidden: true, meta: { title: "智售云-登录" } },
    {
      path: "/forget_the_password",
      name: "/forget_the_password",
      component: ForgetThePassword,
      hidden: true,
      meta: { title: "智售云-重置密码" }
    },
    { path: "/not_found", name: "/not_found", component: NotFound, hidden: true, meta: { title: "页面未找到" } }
  ]
});

export const loadView = (view) => {
  if (process.env.NODE_ENV === "development") {
    return (resolve) => require([`@/views/${view}`], resolve);
    // return () => import(`@/views/${view}`)
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/views/${view}`);
  }
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, type = false) {
  return asyncRouterMap.filter((route) => {
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.meta) route.meta.hidden = route.hidden;
    else route.meta = { hidden: route.hidden };
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === "Layout") {
        route.component = Layout;
      } else if (route.component === "ParentView") {
        route.component = ParentView;
      } else if (route.component === "InnerLink") {
        route.component = InnerLink;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, type);
    } else {
      delete route["children"];
      delete route["redirect"];
    }
    return true;
  });
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = [];
  childrenMap.forEach((el) => {
    if (el.children && el.children.length) {
      if (el.component === "ParentView" && !lastRouter) {
        el.children.forEach((c) => {
          c.path = el.path + "/" + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + "/" + el.path;
      if (el.children && el.children.length) {
        children = children.concat(filterChildren(el.children, el));
        return;
      }
    }
    children = children.concat(el);
  });
  return children;
}

export function getInfo() {
  return get("/sys_role/user_info")
    .then((res) => {
      store.state.userInfo = res.data.userInfo;
      store.state.permissions = res.data.permissions;
      const rdata = JSON.parse(JSON.stringify(res.data.routers));
      const rewriteRoutes = filterAsyncRouter(rdata, true);
      //获取菜单第一个可用页面
      let homePath = "/";
      for (let menu of rewriteRoutes) {
        if (!menu.hidden && menu.path && (!menu.children || menu.children == 0) && menu.component != Layout) {
          homePath = menu.path;
          break;
        }
        if (menu.children) {
          for (let submenu of menu.children) {
            if (!submenu.hidden && submenu.path && (!submenu.children || submenu.children == 0) && submenu.component != Layout) {
              homePath = submenu.path;
              break;
            }
            if (submenu.children) {
              for (let submenu2 of submenu.children) {
                if (!submenu2.hidden && submenu2.path && (!submenu2.children || submenu2.children == 0) && submenu2.component != Layout) {
                  homePath = submenu2.path;
                  break;
                }
              }
              if (homePath) break;
            }
          }
          if (homePath) break;
        }
      }
      // console.debug('homePath is ' + homePath)
      if (homePath != "/") {
        store.state.homePath = homePath;
        rewriteRoutes.unshift({ path: "/", name: "/home", redirect: homePath, hidden: true, meta: { title: "主页" } });
      }
      rewriteRoutes.push({ path: "*", redirect: "/not_found", hidden: true });
      store.state.routers = rewriteRoutes;
      if (router.getRoutes().length > router.options.routes.length) {
        router.matcher = new Router(router.options).matcher; // 重置路由匹配器
        router.addRoutes(rewriteRoutes); // 重新添加剩余路由配置
      } else {
        router.addRoutes(rewriteRoutes); // 动态添加可访问路由表
      }
      return true;
    })
    .catch((res) => {
      console.error("调用user_info接口失败", res);
    });
}

/**
 * 路由跳转时验证授权
 */
router.beforeEach((to, from, next) => {
  let token = util.getCookie("access-token");
  if (!token && window.__wxjs_environment === "miniprogram") {
    //运营助手打开的
    if (to.query.token) {
      store.commit("clearUserInfo");
      token = to.query.token;
      setCookie("access-token", token);
    }
  } else {
    if (to.query.token && to.query.token != token) {
      //切换用户或token了
      store.commit('clearUserInfo')
      token = to.query.token;
      setCookie('access-token', token);
    }
  }
  if (token) {
    document.title = to.meta.title ? to.meta.title : "智售云";
    if (to.path.startsWith("/login")) {
      next({path: "/"});
    } else if (to.path.startsWith("/forget_the_password")) {
      next();
    } else {
      if (!store.getters.userInfo.managerType) {
        NProgress.start();
        // 判断当前用户是否已拉取完user_info信息
        getInfo().then(
          (res) => {
            NProgress.done();
            if (res) {
              to = {...to, replace: true};
              delete to.query.token;
              delete to.query.standalone;
              next(to); // hack方法 确保addRoutes已完成
            } else {
              util.removeCookie("access-token");
              next({path: "/login"});
            }
          },
          () => {
            NProgress.done();
          }
        );
        next()
      } else {
        if (to.meta.link) {
          store.dispatch("tagsView/addIframeView", to);
        }
        next();
      }
    }
  } else {
    // 未登录
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      // 不是免认证页面跳转到登录页
      next({
        path: "/login",
        query: to.fullPath && to.fullPath != "/" ? { redirect: to.fullPath } : null
      });
    }
  }
});

export default router;
