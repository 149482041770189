/**
 * 设置sessionStorage
 * @param key
 * @param value
 */
export function setSessionStorage(key, value) {
  window.sessionStorage.setItem(key, value);
}

/**
 * 获取sessionStorage
 * @param key
 */
export function getSessionStorage(key) {
  return window.sessionStorage.getItem(key);
}

/**
 * 获取sessionStorage
 * @param key
 */
export function removeSessionStorage(key) {
  window.sessionStorage.removeItem(key);
}

/**
 * 获取cookie
 * @param key
 */
export function getCookie(key) {
  let value = null;
  let cookies = document.cookie.split("; ");
  for (let item of cookies) {
    if (item) {
      let keyVal = item.split("=");
      keyVal[0] === key && (value = keyVal[1]);
    }
  }
  return value;
}

/**
 * 设置cookie
 * @param key
 * @param value
 * @param expire cookie 单位秒 有效期默认1小时
 */
export function setCookie(key, value, expire) {
  if (expire) {
    let nowTime = new Date();
    let expireTime = nowTime.getTime() + expire * 1000;
    nowTime.setTime(expireTime);
    document.cookie = `${key}=${value}; expires=` + nowTime.toGMTString();
  } else {
    if (expire === 0) {
      let nowTime = new Date();
      let expireTime = nowTime.getTime() + 3600 * 24 * 365 * 1000;
      nowTime.setTime(expireTime);
      document.cookie = `${key}=${value}; expires=` + nowTime.toGMTString();
    } else {
      document.cookie = `${key}=${value};`;
    }
  }
}

/**
 * 通过cookie key 删除
 */
export function removeCookie(key) {
  let cookies = document.cookie.split("; ");
  for (let item of cookies) {
    if (!item) {
      continue;
    }
    let keyVal = item.split("=");
    if (keyVal[0] === key) {
      document.cookie = `${keyVal[0]}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }
}

Number.prototype.toRound = function (num) {
  if (isNaN(num)) {
    return this.toString();
  }
  let multiplier = Math.pow(10, num);
  return parseFloat((Math.round(this * multiplier) / multiplier).toFixed(num));
};

String.prototype.toRound = function (num) {
  if (isNaN(num)) {
    return this;
  }
  return parseFloat(this).toRound(num);
};

Date.prototype.format = function (fmt) {
  let o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    }
  }
  return fmt;
};

//把分钟数转化成 x天x小时x分钟
export function formatMinutes(minutes) {
  var days = Math.floor(minutes / (24 * 60));
  var hours = Math.floor((minutes % (24 * 60)) / 60);
  var remainingMinutes = minutes % 60;

  var result = "";
  if (days > 0) {
    result += days + "天";
  }
  if (hours > 0) {
    result += hours + "小时";
  }
  if (remainingMinutes > 0) {
    result += remainingMinutes + "分钟";
  }
  return result;
}

//判断一个对象是不是 JSON 对象
export function isJSONObject(data) {
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 *去重
 * @param arr=[]
 * @returns arr[]
 */
export function arrTwoDetel(arr) {
  var newArr = [];
  for (var i = 0; i < arr.length; i++) {
    var repArr = []; //接收重复数据后面的下标
    //内层循环找出有重复数据的下标
    for (var j = i + 1; j < arr.length; j++) {
      if (arr[i].orderId == arr[j].orderId) {
        repArr.push(j); //找出后面重复数据的下标
      }
    }
    if (repArr.length == 0) {
      //若重复数组没有值说明其不是重复数据
      newArr.push(arr[i]);
    }
  }
  return newArr;
}

/**
 * 更新地址栏查询
 * @param params={}
 */
export function pushQuery(params) {
  if (!params) {
    this.$router.push(
      { query: null },
      () => {},
      () => {}
    );
    return;
  }
  let newQuery = {};
  for (let key in params) {
    let val = params[key];
    if (val === null || val === "") continue;
    if (key === "currPage" && val == 1) continue;
    if (key === "pageSize" && val == 10) continue;
    if (Array.isArray(val)) {
      if (val.length === 0) continue;
      if (val.length == 2) {
        const now = new Date();
        if (
          (val[0] == now.format("yyyy-MM-dd 00:00:00") || val[0] == now.format("yyyy-MM-dd")) &&
          (val[1] == now.format("yyyy-MM-dd 23:59:59") || val[1] == now.format("yyyy-MM-dd"))
        )
          continue;
      }
      newQuery[key] = val.toString();
    } else {
      newQuery[key] = val;
    }
  }
  let currQuery = {};
  for (let key in this.$route.query) {
    let val = this.$route.query[key];
    if (val === null || val === "") continue;
    if (Array.isArray(val)) {
      if (val.length === 0) continue;
      currQuery[key] = val.toString();
    } else {
      currQuery[key] = val;
    }
  }
  let n = Object.getOwnPropertyNames(newQuery);
  let c = Object.getOwnPropertyNames(currQuery);
  if (n.length == c.length) {
    let isSame = true;
    for (let i = 0; i < n.length; i++) {
      let propName = n[i];
      if (!currQuery.hasOwnProperty(propName) || newQuery[propName] != currQuery[propName]) {
        isSame = false;
        break;
      }
    }
    if (isSame) {
      return;
    }
  }
  this.$router.push(
    { query: newQuery },
    () => {},
    () => {}
  );
}

/**
 * 获取地址栏查询参数并更新到parent或this对象
 * @param parent 更新到额外的自定义对象parent，parent内不存在同名参数时更新到this下
 * @returns {{}}
 */
export function pullQuery(parent) {
  let result = {};
  for (let key in this.$route.query) {
    let val = this.$route.query[key];
    if (val || val === 0) {
      if (parent && parent[key] !== undefined) {
        if (parent[key] instanceof Array) {
          result[key] = val.split(",");
        } else if (parent[key] == null) {
          if (val.indexOf(",") >= 0) result[key] = val.split(",");
          else result[key] = val;
        } else if (typeof parent[key] == "number") {
          result[key] = Number(val);
        } else {
          result[key] = val;
        }
        parent[key] = result[key];
      } else if (this[key] !== undefined) {
        if (this[key] instanceof Array) {
          result[key] = val.split(",");
        } else if (this[key] == null) {
          if (val.indexOf(",") >= 0) result[key] = val.split(",");
          else result[key] = val;
        } else if (typeof this[key] == "number") {
          result[key] = Number(val);
        } else {
          result[key] = val;
        }
        this[key] = result[key];
      } else {
        if (typeof val == "string") {
          if (val.indexOf(",") >= 0) {
            result[key] = val.split(",");
            console.warn("Unknown url query parameter type " + key);
          } else {
            result[key] = val;
          }
        } else {
          result[key] = val;
        }
      }
    }
  }
  // return result //不再返回数据，请使用函数getQuery替代
}

/**
 * 获取地址栏查询数据，不更新this下变量
 * @param template 使用模板指定类型，如：{param1:0, param2:'1', param3:true, param4:[]}
 * @returns {{}}
 */
export function getQuery(template) {
  let result = {};
  for (let key in this.$route.query) {
    let val = this.$route.query[key];
    if (val || val === 0) {
      if (template && template[key] !== undefined) {
        if (template[key] instanceof Array) {
          result[key] = val.split(",");
        } else if (template[key] == null) {
          if (val.indexOf(",") >= 0) result[key] = val.split(",");
          else result[key] = val;
        } else if (typeof template[key] == "number") {
          result[key] = Number(val);
        } else {
          result[key] = val;
        }
      } else if (this[key] !== undefined) {
        if (this[key] instanceof Array) {
          result[key] = val.split(",");
        } else if (this[key] == null) {
          if (val.indexOf(",") >= 0) result[key] = val.split(",");
          else result[key] = val;
        } else if (typeof this[key] == "number") {
          result[key] = Number(val);
        } else {
          result[key] = val;
        }
      } else {
        if (typeof val == "string") {
          if (val.indexOf(",") >= 0) {
            result[key] = val.split(",");
            console.warn("Unknown url query parameter type " + key);
          } else {
            result[key] = val;
          }
        } else {
          result[key] = val;
        }
      }
    }
  }
  return result;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
  let config = {
    id: id || "id",
    parentId: parentId || "parentId",
    childrenList: children || "children"
  };

  var childrenListMap = {};
  var nodeIds = {};
  var tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }

  return tree;
}

// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    } else if (typeof time === "string") {
      time = time
        .replace(new RegExp(/-/gm), "/")
        .replace("T", " ")
        .replace(new RegExp(/\.[\d]{3}/gm), "");
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

//取一天的日期范围
export function getOneDay() {
  let now = new Date();
  let time =
    now.getFullYear() + "-" + (now.getMonth() + 1 < 10 ? "0" : "") + (now.getMonth() + 1) + "-" + (now.getDate() < 10 ? "0" : "") + now.getDate();
  return [time, time];
}

//取一个月的日期范围
export function getMonthDay() {
  let now = new Date();
  let time =
    now.getFullYear() + "-" + (now.getMonth() + 1 < 10 ? "0" : "") + (now.getMonth() + 1) + "-" + (now.getDate() < 10 ? "0" : "") + now.getDate();
  let time1 = now.getFullYear() + "-" + (now.getMonth() + 1 < 10 ? "0" : "") + now.getMonth() + "-" + (now.getDate() < 10 ? "0" : "") + now.getDate();
  return [time1, time];
}

/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
export const Debounce = (fn, t) => {
  const delay = t || 500;
  let timer;
  return function () {
    const args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};

export function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // 检查常见的移动设备标识
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
}

export function compare(oldVal, newVal) {
  // if (oldVal == newVal) return false;
  // else return true;
  return JSON.stringify(oldVal) == JSON.stringify(newVal);
}

export function deepClone(obj, hash = new WeakMap()) {
  if (obj === null) return null; // null 的情况
  if (obj instanceof Date) return new Date(obj); // 日期对象直接返回一个新的日期对象
  if (obj instanceof RegExp) return new RegExp(obj); // 正则对象直接返回一个新的正则对象
  // 如果循环引用了就用 weakMap 来解决
  if (hash.has(obj)) return hash.get(obj);

  let allDesc = Object.getOwnPropertyDescriptors(obj);
  let cloneObj = Object.create(Object.getPrototypeOf(obj), allDesc);
  hash.set(obj, cloneObj);

  for (let key of Reflect.ownKeys(obj)) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      cloneObj[key] = deepClone(obj[key], hash);
    } else {
      cloneObj[key] = obj[key];
    }
  }
  return cloneObj;
}
