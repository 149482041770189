<template>
  <el-header class="header">
    <div class="header-item" v-if="!(isAdmin && $store.state.screenWidth < 768 && fixedHeader)">
      <img v-if="!isCollapse" class="header_img" src="@/assets/images/ad_top_sf.png" @click="changeCollapse" />
      <img v-else class="header_img" src="@/assets/images/ad_top_left.jpg" @click="changeCollapse" />
      <breadcrumb id="breadcrumb-container" style="min-width: 200px" />
    </div>
    <div class="header-item" style="display: flex">
      <div v-if="isAdmin && $store.getters.hasMenu">
        <span class="select_name">当前加盟商：</span>
        <div class="select">
          <el-select
            @change="changeFranchisee"
            v-model="franchiseeId"
            size="small"
            filterable
            :default-first-option="true"
            placeholder="请选择加盟商"
            :filter-method="filterFranchisee"
            @focus="focusFranchisee"
            popper-class="franchisee-dropdown"
          >
            <el-option v-for="item in franchiseeList" :key="item.franchiseeId" :label="item.mchName" :value="item.franchiseeId">
              <!-- <div> -->
              <span style="float: left">{{ item.mchName }}</span>
              <span style="color: #8492a6">({{ item.realName == item.mchName ? item.loginName : item.realName }})</span>
              <el-badge v-if="item.approveNum" class="bad" :value="`待审批`"></el-badge>
              <!-- <span v-if="item.approveNum" style="color: orange; font-size: 13px;margin: 0 10px;">商品审批数:{{ item.approveNum }}个</span> -->
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceCount }}台</span>
              <!-- </div> -->
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="right-menu">
        <!--              <span class="iconfont iconios-notifications-outline right-menu-item"-->
        <!--                    style="font-size:22px;opacity:.5;"></span>-->
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="hover">
          <div class="avatar-wrapper">
            <img style="width: 18px; height: 18px" src="@/assets/images/26x26.png" />
            {{ $store.getters.userInfo.managerRealName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" style="width: 120px">
            <router-link to="/" v-if="$store.getters.hasMenu">
              <el-dropdown-item icon="el-icon-s-home">主页</el-dropdown-item>
            </router-link>
            <router-link :to="{ path: '/basic_information' }" v-if="$store.getters.hasMenu">
              <el-dropdown-item icon="el-icon-s-custom">个人中心</el-dropdown-item>
            </router-link>
            <!--                <el-dropdown-item icon="el-icon-files" @click.native="showTagsView = true">-->
            <!--                </el-dropdown-item>-->
            <el-dropdown-item
              :icon="fixedHeader ? 'el-icon-check' : 'el-checkbox__inner'"
              style="white-space: nowrap"
              @click.native="handleFixedHeader"
              >固定头部
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" @click.native="handleLogout" divided>
              <span>退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </el-header>
</template>

<script>
import store from "../store";
import Breadcrumb from "@/components/Breadcrumb";
import { EventBus } from "@/event";
import { getCookie, isMobile, setCookie } from "../util";

export default {
  name: "AppHeader",
  data() {
    return {
      franchiseeId: "",
      franchiseeAll: [], //所有加盟商
      franchiseeList: [],
      isCollapse: store.state.isCollapse
    };
  },
  components: {
    Breadcrumb
  },
  computed: {
    fixedHeader: {
      get() {
        return this.$store.state.fixedHeader;
      },
      set(val) {
        this.$store.commit("updateFixedHeader", val);
      }
    }
    // franchiseeAll: {
    //   get() {
    //     return this.$store.state.franchiseeList;
    //   }
    // }
  },
  methods: {
    getFranchisee() {
      this.get("/franchiseeBaseInfo/query_all_franchisee").then((res) => {
        this.franchiseeId = decodeURIComponent(this.util.getCookie("franchisee_id"));
        this.franchiseeAll = res.data; //.sort((x, y) => x.realName.localeCompare(y.realName));
        this.franchiseeList = this.franchiseeAll;
        this.$store.commit("setFranchiseeList", this.franchiseeAll);
        this.$store.commit("setResFranchiseeList", this.franchiseeAll); //存一份原始值
      });
    },
    handleFixedHeader() {
      setCookie("fixedHeader", !this.fixedHeader, 0);
      this.fixedHeader = !this.fixedHeader;
    },
    handleLogout() {
      const that = this;
      this.get("authority/logout").then(
        (res) => {
          if (res.errorCode != 0) {
            that.$message.error(res.message);
          }
          that.util.removeCookie("access-token");
          let fullPath = that.$route.fullPath;
          if (!fullPath) {
            fullPath = "";
          }
          that.$router.replace(
            {
              path: "/login?redirect=" + fullPath
            },
            () => {},
            () => {}
          );
        },
        () => {
          that.util.removeCookie("access-token");
          let fullPath = that.$route.fullPath;
          if (!fullPath) {
            fullPath = "";
          }
          that.$router.replace(
            {
              path: "/login?redirect=" + fullPath
            },
            () => {},
            () => {}
          );
        }
      );
    },
    focusFranchisee() {
      this.franchiseeList = this.franchiseeAll;
    },
    filterFranchisee(query) {
      this.franchiseeList = this.franchiseeAll.filter((option) => {
        return (
          option.mchName.includes(query) ||
          option.realName.includes(query) ||
          (option.loginName && option.loginName.toLowerCase().includes(query.toLowerCase()))
        );
      });
    },
    changeFranchisee(franchiseeId, noRefresh) {
      this.franchiseeId = franchiseeId;
      this.util.setCookie("franchisee_id", franchiseeId, 0);
      this.$store.commit("setPartnerList", []);
      this.$store.commit("setFranchiseeList", this.franchiseeList); //切换时，让left渲染不同的审批数
      EventBus.$emit("getNewMsg", true);
      if (!noRefresh) {
        EventBus.$emit("refreshPage", franchiseeId);
        // this.$router.go(0);
      }
    },
    changeCollapse() {
      this.$store.commit("updateIsCollapse", !this.$store.state.isCollapse);
      this.isCollapse = this.$store.state.isCollapse;
    }
  },
  created() {
    if (this.isAdmin) {
      this.getFranchisee();
    }
    EventBus.$on("newGetApproveNum", () => {
      if (this.isAdmin) {
        this.getFranchisee();
      }
    });
  },
  mounted() {
    EventBus.$on("changeFranchisee", (franchiseeId, router) => {
      this.changeFranchisee(franchiseeId, router ? true : false);
      if (router) {
        this.$router.push(router);
      }
    });
    let fixedHeader = getCookie("fixedHeader");
    if (fixedHeader !== null) {
      this.fixedHeader = fixedHeader ? true : false;
    } else if (isMobile()) {
      this.fixedHeader = false;
    }
    // if (window.history && window.history.pushState) {
    //     history.pushState(null, null, document.URL);
    //     window.addEventListener('popstate', this.pushState, false);
    // }
  },
  beforeDestroy() {
    EventBus.$off("changeFranchisee");
  }
};
</script>
<style lang="scss">
.bad > .el-badge__content {
  font-size: 10px !important;
}
.select {
  display: inline-block;
  width: 200px;
  height: 45px;
  position: relative;
  vertical-align: middle;
  padding: 0;
  box-sizing: border-box;
  margin-top: -3px;
}

.header {
  height: 50px !important;
  line-height: 48px;
  padding: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  /* 两端对齐 */
  align-items: center;
  /* 垂直居中对齐 */
  flex-wrap: nowrap;
  /* 不换行 */
}

.header-item {
  white-space: nowrap;
  height: 50px;
}

.header-item:first-child {
  flex-grow: 1;
  /* 第一个元素占满剩余空间 */
}
.header-item:last-child {
  margin-left: 20px;
}

.header_img {
  padding: 10px;
}

.breadcrumb-container {
  float: left;
}

.right-menu {
  display: inline-block;
  height: 100%;
  line-height: 50px;
  text-align: right;

  &:focus {
    outline: none;
  }

  .right-menu-item {
    display: inline-block;
    padding: 0 8px;
    height: 100%;
    font-size: 18px;
    color: #5a5e66;
    vertical-align: text-bottom;
    min-width: 80px;

    &.hover-effect {
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }
  }

  .avatar-container {
    //margin-right: 30px;

    .avatar-wrapper {
      position: relative;
      font-size: 14px;

      .user-avatar {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }

      .el-icon-caret-bottom {
        cursor: pointer;
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }
}
.franchisee-dropdown .el-select-dropdown__wrap {
  max-height: 460px; /* 自定义高度 */
}
</style>
