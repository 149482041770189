<template>
  <div style="height: calc(100vh - 70px)" v-loading="loading" element-loading-text="正在加载页面，请稍候！">
    <iframe :id="iframeId" style="width: 100%; height: 100%" :src="url" frameborder="no"></iframe>
  </div>
</template>

<script>
import NProgress from "nprogress";

export default {
  props: {
    src: {
      type: String,
      default: "/"
    },
    iframeId: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      url: null
    };
  },
  mounted() {
    var _this = this;
    const iframeId = ("#" + this.iframeId).replace(/\//g, "\\/");
    const iframe = document.querySelector(iframeId);
    // iframe页面loading控制
    if (iframe.attachEvent) {
      this.loading = true;
      iframe.attachEvent("onload", function () {
        _this.loading = false;
      });
    } else {
      this.loading = true;
      iframe.onload = function () {
        _this.loading = false;
      };
    }
    if (this.src.includes("{auth_code}")) {
      NProgress.start();
      this.post("authority/create_auth_code").then(
        (res) => {
          NProgress.done();
          if (res.errorCode == 0) {
            this.url = this.src.replace("{auth_code}", res.data.code);
          }
        },
        (res) => {
          NProgress.done();
          this.$message.error(res);
        }
      );
    } else if (this.src.includes("{host}")) {
      let host = window.location.host;
      this.url = this.src.replace("{host}", host);
    } else {
      this.url = this.src;
    }
  }
};
</script>
