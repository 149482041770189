<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item :key="-1">
        <a @click.prevent="handleLink({ path: '/', meta: { title: '主页' } })">主页</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
        <span v-if="item.redirect === 'noRedirect' || !item.path" class="no-redirect"> {{ item.meta.title }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import store from "../store";

export default {
  data() {
    return {
      levelList: []
    };
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    }
  },
  mounted() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter((item) => item.meta.title);
      if (matched.length > 0) {
        if (matched[matched.length - 1].meta.hidden) {
          this.levelList = [...this.levelList, matched[matched.length - 1]];
          return;
        }
      }
      this.levelList = matched;
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        if (this.$route.path == redirect || this.$route.path == redirect.path) {
          return;
        }
        this.$router.push(redirect);
        return;
      }
      if (!path || this.$route.path == path) {
        return;
      }
      if (path == "/" && this.$route.path == store.state.homePath) {
        return;
      }
      this.$router.push(path);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-breadcrumb__item {
  float: none !important;
}
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  a {
    font-weight: normal;
  }

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
