<template>
  <section class="app-main">
    <el-alert v-if="showMessage && messagePrefix" type="warning" show-icon center :closable="!isAdmin">
      <div slot="title" @click="clickMessage" :style="isAdmin ? 'cursor: pointer' : ''">
        <el-statistic
          ref="statistic"
          :format="messageFormat"
          :value="endUpdateTime"
          :prefix="messagePrefix"
          :suffix="messageSuffix"
          time-indices
          @finish="closeUpdatingMessage"
          :value-style="{ 'font-size': '13px' }"
        ></el-statistic>
      </div>
    </el-alert>
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view v-if="showPage && !$route.meta.link" :key="key" ref="appMain" />
      </keep-alive>
      <!--      <router-view v-if="!$route.meta.keepAlive" :key="key"/>-->
    </transition>
    <iframe-toggle />
    <el-dialog title="系统升级维护" :visible.sync="systemUpdatingVisible">
      <el-alert
        title="升级过程中会暂停下方选择的服务部分业务功能的使用，升级结束并测试正常后请执行【完成升级】操作"
        type="warning"
        show-icon
        style="margin-bottom: 20px"
      ></el-alert>
      <el-form :model="form" :disabled="loading">
        <el-form-item label="暂停业务服务" label-width="120px" required>
          <el-select v-model="form.platform" placeholder="请选择需要暂停业务的服务" multiple style="width: 100%">
            <el-option label="SaaS服务" value="saas"></el-option>
            <el-option label="运营助手服务" value="weapp"></el-option>
            <el-option label="消费者服务" value="vmp"></el-option>
            <el-option label="人机交互服务" value="vending"></el-option>
            <el-option label="聚合支付服务" value="payment" :disabled="true"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="升级提示信息" label-width="120px" required>
          <my-input
            name="updatingMessage"
            ref="updatingMessage"
            v-model="form.message"
            :suggestions="suggestions"
            placeholder="请输入或选择升级提示信息"
            style="width: 100%"
          ></my-input>
        </el-form-item>
        <el-form-item label="升级白名单" label-width="120px">
          <my-input
            name="updatingWhitelist"
            ref="updatingWhitelist"
            v-model="form.whitelist"
            placeholder="请输入白名单设备id或用户id供升级后测试，多个用半角逗号分隔"
            style="width: 100%"
          ></my-input>
        </el-form-item>
        <el-form-item label="升级预估时长" label-width="120px" required>
          <el-select v-model="form.duration" placeholder="请选择升级预估时长" style="width: 100%" @change="onChangeDuration">
            <el-option
              v-for="item in durationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="updatingInfo != null && item.value <= minSecond"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="updatingInfo" label="升级耗时" label-width="120px">
          <el-statistic
            :key="form.duration"
            format="mm分钟ss秒后结束"
            :value="endUpdateTime"
            time-indices
            :value-style="{ 'font-size': '15px' }"
            @finish="finishUpdating"
            @change="onChangeTime"
            style="display: inline-block; text-align: left"
          >
            <template slot="prefix">
              <el-progress :percentage="percentage" style="display: inline-block; width: 450px"></el-progress>
            </template>
          </el-statistic>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button @click="systemUpdatingVisible = false" style="margin-right: 20px">取 消</el-button>
          <el-button @click="deleteUpdatingInfo" style="margin-right: 20px" :disabled="loading || updatingInfo == null"> 完成升级 </el-button>
          <el-button type="primary" @click="handleUpdating" :disabled="loading">{{ updatingInfo ? "更 新" : "确 定" }} </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </section>
</template>

<script>
import iframeToggle from "./IframeToggle/index";
import { EventBus } from "@/event";
import { Debounce, isMobile } from "@/util";
import MyInput from "/src/components/MyInput";

export default {
  name: "AppMain",
  components: { iframeToggle, MyInput },
  data() {
    return {
      showPage: true,
      showMessage: true,
      messageFormat: "mm:ss",
      messagePrefix: null,
      messageSuffix: null,
      endUpdateTime: null,
      systemUpdatingVisible: false,
      percentage: 0,
      minSecond: 24 * 60 * 60,
      form: { platform: ["saas", "weapp", "vending", "vmp"], whitelist: "" },
      updatingInfo: null,
      loading: false,
      durationOptions: [
        { label: "1分钟", value: 1 * 60 },
        { label: "3分钟", value: 3 * 60 },
        { label: "5分钟", value: 5 * 60 },
        { label: "10分钟", value: 10 * 60 },
        { label: "15分钟", value: 15 * 60 },
        { label: "20分钟", value: 20 * 60 },
        { label: "25分钟", value: 25 * 60 },
        { label: "30分钟", value: 30 * 60 },
        { label: "40分钟", value: 40 * 60 },
        { label: "50分钟", value: 50 * 60 },
        { label: "1小时", value: 60 * 60 },
        { label: "2小时", value: 120 * 60 }
      ],
      suggestions: [
        { value: "系统升级维护中，请稍后使用" },
        { value: "系统升级维护中，部分业务无法使用，请稍后重试" },
        { value: "系统升级维护中，请在{remain}后再使用" },
        { value: "系统升级维护中，请稍后使用，升级预计耗时{remain}" }
      ]
    };
  },
  computed: {
    cachedViews() {
      return this.$store.state.cacheView; //tagsView.cachedViews
      // return ["commodityAudit"]; //tagsView.cachedViews
    },
    key() {
      return this.$route.path;
    }
  },
  methods: {
    handleResize() {
      let width = document.body.clientWidth;
      this.$store.commit("updateScreenWidth", width);
      if (width < 768) {
        this.$store.commit("updateIsCollapse", true);
      } else {
        this.$store.commit("updateIsCollapse", false);
      }
    },
    processUpdatingMessage(data) {
      this.updatingInfo = data;
      this.form = data ? { ...data } : { platform: ["saas", "weapp", "vending", "vmp"] };
      if (data) {
        this.endUpdateTime = Date.now() + data.remain * 1000;
        this.messageFormat = data.remain > 3600 ? "HH小时mm分钟ss秒" : "mm分钟ss秒";
        let index = data.message.indexOf("{remain}");
        if (index >= 0) {
          this.messagePrefix = data.message.substring(0, index);
          this.messageSuffix = data.message.substring(index + 8);
        } else {
          this.messagePrefix = data.message;
          this.messageSuffix = null;
        }
      } else {
        this.messagePrefix = null;
        this.messageSuffix = null;
        this.endUpdateTime = null;
      }
    },
    closeUpdatingMessage() {
      this.messagePrefix = null;
      this.messageSuffix = null;
      this.endUpdateTime = null;
    },
    clickMessage() {
      if (!this.isAdmin || !this.hasPermit("system/updating/info")) {
        this.closeUpdatingMessage();
        return;
      }
      this.openUpdateDialog();
    },
    openUpdateDialog() {
      this.minSecond = 24 * 60 * 60;
      this.systemUpdatingVisible = true;
      this.loading = true;
      this.get("system/updating/info").then(
        (res) => {
          this.loading = false;
          if (res.errorCode == 0) {
            this.processUpdatingMessage(res.data);
          }
        },
        () => {
          this.loading = false;
        }
      );
    },
    handleUpdating() {
      if (!this.form.platform) {
        this.$message.warning("请选择待升级的服务");
        return;
      }
      if (!this.form.message) {
        this.$message.warning("请输入升级提示信息");
        return;
      }
      if (!this.form.duration) {
        this.$message.warning("请选择升级预估时长");
        return;
      }
      if (this.form.whitelist && this.form.whitelist.includes("，")) {
        this.form.whitelist = this.form.whitelist.replaceAll("，", ",").replaceAll(" ", ",");
      }
      this.loading = true;
      let param = { ...this.form };
      this.post("system/updating/update", param).then(
        (res) => {
          this.loading = false;
          if (res.errorCode == 0) {
            this.processUpdatingMessage(res.data);
          }
        },
        () => {
          this.loading = false;
        }
      );
    },
    deleteUpdatingInfo() {
      this.loading = true;
      this.post("system/updating/delete").then(
        (res) => {
          this.loading = true;
          if (res.errorCode == 0) {
            this.systemUpdatingVisible = false;
            this.processUpdatingMessage(null);
          }
        },
        () => {
          this.loading = false;
        }
      );
    },
    finishUpdating() {
      if (Date.now() > Date.parse(this.updatingInfo.endTime)) {
        this.systemUpdatingVisible = false;
      }
    },
    onChangeDuration(duration) {
      if (this.updatingInfo) {
        let endUpdateTime = Date.parse(this.updatingInfo.startTime) + duration * 1000;
        if (endUpdateTime < Date.now() + 30000) {
          this.endUpdateTime = Date.now();
          this.onChangeTime(0);
          return;
        }
        this.endUpdateTime = endUpdateTime;
      }
    },
    onChangeTime(res) {
      if (!this.updatingInfo) {
        return;
      }
      let percentage = (((this.form.duration - res / 1000) * 100) / this.form.duration).toRound(0);
      if (percentage < 0) percentage = 0;
      else if (percentage > 100) percentage = 100;
      this.percentage = percentage;
      if (Date.now() > Date.parse(this.updatingInfo.endTime)) {
        this.systemUpdatingVisible = false;
      }
      let minSecond = ((Date.now() - Date.parse(this.updatingInfo.startTime)) / 1000).toRound(0);
      if (this.minSecond != minSecond) this.minSecond = minSecond;
    }
  },
  mounted() {
    EventBus.$on("refreshPage", (franchiseeId) => {
      //子页面如果实现了refreshPage(franchiseeId)方法则直接调用，否则重新加载页面
      if (this.$refs.appMain && this.$refs.appMain.$children.length > 0 && this.$refs.appMain.$children[0].refreshPage) {
        this.$refs.appMain.$children[0].refreshPage(franchiseeId);
      } else {
        this.showPage = false;
        this.$nextTick(() => {
          this.showPage = true;
        });
      }
    });
    EventBus.$on("processUpdatingMessage", (data) => {
      this.processUpdatingMessage(data);
    });
    this.handleResize();
    //这块debounce里面写成箭头函数让this拿mounted里面得this;
    //debounce返回得函数是window调用，写成普通函数this指向window
    window.onresize = Debounce(() => {
      this.handleResize();
    });
    if (window.screen.width < 768 && isMobile()) {
      this.$message.warning("当前页面建议切换横屏查看");
    }
  },
  beforeDestroy: function () {
    EventBus.$off("refreshPage");
    EventBus.$off("processUpdatingMessage");
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
.app-main {
  /* 45= navbar  45  */
  min-height: calc(100vh - 45px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 4px 2px 5px #eee inset;
}

.fixed-header + .app-main {
  padding-top: 60px;
}

.hasTagsView {
  .app-main {
    /* 79 = navbar + tags-view = 45 + 34 */
    min-height: calc(100vh - 79px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 6px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 3px;
}
</style>
