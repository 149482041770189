<template>
  <div id="app">
    <!-- 需要授权的页面，非登录页 -->
    <el-container id="main" v-if="showLeft && !standalone">
      <!-- 侧边导航栏 -->
      <Left />
      <el-container direction="vertical" :class="{ 'sidebar-collapse': isCollapse, 'sidebar-not-collapse': !isCollapse }">
        <div
          :class="{ 'fixed-header': fixedHeader, 'header-collapse': isCollapse && fixedHeader, 'header-not-collapse': !isCollapse && fixedHeader }"
        >
          <!-- 顶部导航栏 -->
          <Header v-if="$store.getters.userInfo.managerType" />
          <tags-view />
        </div>
        <AppMain />
      </el-container>
    </el-container>
    <!-- 登录页等不需要侧边和页眉页面 -->
    <router-view id="single-page" v-else :key="$route.path" />
    <el-backtop :visibility-height="300"></el-backtop>
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import Left from "./components/Left.vue";
import AppMain from "./components/AppMain.vue";
import tagsView from "./components/tagsView";
import { EventBus } from "@/event";
export default {
  name: "App",
  data() {
    return {
      standalone: false,
      franchiseeAll: []
    };
  },
  components: {
    Header,
    Left,
    AppMain,
    tagsView
  },
  computed: {
    showLeft() {
      const whiteList = ["/login", "/forget_the_password"];
      for (let path of whiteList) {
        if (this.$route.path.startsWith(path)) {
          return false;
        }
      }
      return true;
    },
    fixedHeader() {
      return this.$store.state.fixedHeader;
    },
    isCollapse() {
      return this.$store.state.isCollapse;
    }
  },
  methods: {

  },
  created() {
    if (this.$route.query.standalone) {
      this.standalone = true;
    }
    // if (this.isAdmin) {
      // this.getFranchisee();
    // }
    
  }
};
</script>
<style>
#app {
  font-family: "Microsoft YaHei", "微软雅黑", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #303133;
  width: 100%;
  height: 100%;
}

.sidebar-collapse {
  padding-left: 65px;
  transition: padding-left 0.4s;
}

.sidebar-not-collapse {
  padding-left: 200px;
  transition: padding-left 0.4s;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  transition: width 0.4s;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(200, 200, 200, 0.3);
  box-shadow: 0px 2px 10px 0px rgba(200, 200, 200, 0.3);
}

.header-collapse {
  width: calc(100% - 65px);
}

.header-not-collapse {
  width: calc(100% - 200px);
}

@media screen and (max-width: 900px) {
  #main {
    width: 900px;
  }
}

/*@media screen and (max-width: 400px) {*/
/*  #single-page {*/
/*    width: 400px;*/
/*  }*/
/*}*/

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.sidebarHide .fixed-header {
  width: 100%;
}

.mobile .fixed-header {
  width: 100%;
}
</style>
