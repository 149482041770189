import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=5e4ffc90&scoped=true"
import script from "./NotFound.vue?vue&type=script&lang=js"
export * from "./NotFound.vue?vue&type=script&lang=js"
import style0 from "./NotFound.vue?vue&type=style&index=0&id=5e4ffc90&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4ffc90",
  null
  
)

export default component.exports