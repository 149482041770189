import store from "@/store";

import {getCookie} from "@/util";

class Permission {
  //管理员
  isAdmin() {
    return getCookie("user_type") === "ADMIN" || store.getters.userInfo.managerType === "ADMIN";
  }

  //加盟商
  isMain() {
    return getCookie("user_type") === "MAIN" || store.getters.userInfo.managerType === "MAIN";
  }

  //合作商或者补货员
  isChild() {
    return getCookie("user_type") === "CHILD" || store.getters.userInfo.managerType === "CHILD";
  }

  //运维人员
  isExtra() {
    return getCookie("user_type") === "EXTRA" || store.getters.userInfo.managerType === "EXTRA";
  }

  hasPermit(path) {
    return store.getters.permissions.includes(path) || store.getters.permissions.includes('/' + path);
  }
}

export default new Permission();
