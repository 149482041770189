// 封装axios请求
import instance from "./instance";
import qs from "qs";
import {Message} from "element-ui";
import {EventBus} from "../event";

/**
 * 封装get方法
 * @param url 接口地址
 * @param params 请求参数(对象形式)
 * @returns {Promise<any>}
 * @private
 */
export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
        headers: { "Cache-Control": "no-store" },
        ...config,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        }
      })
      .then(
        function (response) {
          let isArraybuffer = response.config.responseType === "arraybuffer";
          if (isArraybuffer) {
            resolve(response.data);
          } else {
            if (response.data.errorCode !== 0) {
              Message({
                message: response.data.message,
                type: "error",
                center: true
              });
              reject(response.data);
            } else {
              resolve(response.data);
            }
          }
        },
        (res) => {
          reject(res);
        }
      );
  });
};

export const post = (url, data = {}, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data, {
        params: params,
        ...config
      })
      .then(
        (response) => {
          let isArraybuffer = response.config.responseType === "arraybuffer";
          if (isArraybuffer) {
            resolve(response.data);
          } else {
            if (response.data.errorCode !== 0) {
              Message({
                message: response.data.message,
                type: "error",
                center: true
              });
              reject(response.data);
            } else {
              resolve(response.data);
            }
            if ((data && data != {}) || (params && params != {})) {
              //用于通知my-input组件保存数据
              EventBus.$emit('httpPost', (data && data != {}) ? data : params);
            }
          }
        },
        (err) => {
          Message({
            message: err,
            type: "error",
            center: true
          });
          reject(err);
        }
      );
  });
};
