<template>
  <el-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    :fetch-suggestions="fetchSuggestions"
    @select="handleSelect"
    ref="autocomplete"
    clearable
    popper-class="my-input-popper"
    @input="handleInput"
  >
    <template v-slot="{ item }">
      <div class="suggestion-item" @mouseover="hoveredItem = item.value" @mouseleave="hoveredItem = null">
        <span>{{ item.value }}</span>
        <div
          v-if="hoveredItem === item.value && shouldShowDeleteIcon(item)"
          class="delete-icon-container"
          @mouseover="hoveredIcon = item.value"
          @mouseleave="hoveredIcon = null"
          @click.stop="deleteItem(item)"
          :class="{ 'hovered': hoveredIcon === item.value }"
        >
          <i class="el-icon-close"></i>
        </div>
      </div>
    </template>
  </el-autocomplete>
</template>

<script>
import {EventBus} from "/src/event";

export default {
  name: 'MyInput',
  inheritAttrs: false,
  props: {
    value: String,
    limit: { // 显示建议选项的最大数量
      type: Number,
      default: 10
    },
    suggestions: { // 默认建议选项
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      allSuggestions: [],
      defaultSuggestions: [],
      hoveredItem: null,
      hoveredIcon: null,
    };
  },
  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
    fetchSuggestions(queryString, cb) {
      let results = this.allSuggestions;
      if (queryString) {
        results = this.allSuggestions.filter(item =>
          item.value.toLowerCase().includes(queryString.toLowerCase()) && item.value != queryString
        );
      }
      cb(results.slice(0, this.limit));
    },
    handleSelect(item) {
      this.$emit('input', item.value);
    },
    handleInput(value) {
      this.$emit('input', value);
    },
    deleteItem(item) {
      let suggestions = [];
      const storedSuggestions = localStorage.getItem(this.$attrs.name);
      if (storedSuggestions) {
        suggestions = JSON.parse(storedSuggestions);
        suggestions = suggestions.filter(i => i.value !== item.value);
        localStorage.setItem(this.$attrs.name, JSON.stringify(suggestions));
        this.allSuggestions = [...suggestions, ...this.defaultSuggestions];
        this.$nextTick(() => {
          this.$refs.autocomplete.fetchSuggestions(this.value, (results) => {
            this.$refs.autocomplete.suggestions = results;
          });
        });
      }
    },
    /**
     * save to history
     * @param value
     */
    save() {
      if (!this.value || this.defaultSuggestions.some(item => item.value === this.value)) {
        return;
      }

      let suggestions = [];
      const storedSuggestions = localStorage.getItem(this.$attrs.name);
      if (storedSuggestions) {
        suggestions = JSON.parse(storedSuggestions);
        if (suggestions.some(item => item.value === this.value)) {
          return;
        }
      }
      suggestions.unshift({value: this.value});
      localStorage.setItem(this.$attrs.name, JSON.stringify(suggestions));
      this.allSuggestions = [...suggestions, ...this.defaultSuggestions];
    },
    shouldShowDeleteIcon(item) {
      // 判断 item 是否在 this.defaultSuggestions 中
      return !this.defaultSuggestions.some(suggestion => suggestion.value === item.value);
    }
  },
  mounted() {
    if (!this.$attrs.name) {
      throw new Error('The "name" attribute is required for MyInput component.');
    }
    if (this.$attrs.autofocus) {
      this.focus();
    }
    if (Array.isArray(this.suggestions) && typeof this.suggestions[0] === 'string') {
      this.defaultSuggestions = this.suggestions.map(item => ({value: item}));
    } else {
      this.defaultSuggestions = this.suggestions;
    }

    let suggestions = [];
    const storedSuggestions = localStorage.getItem(this.$attrs.name);
    if (storedSuggestions) {
      suggestions = JSON.parse(storedSuggestions);
    }
    this.allSuggestions = [...suggestions, ...this.defaultSuggestions];
    EventBus.$on("httpPost", data => {
      if (!this.value) {
        return;
      }
      if (JSON.stringify(data).includes(this.value)) {
        this.save();
      }
    });
  },
  beforeDestroy() {
    EventBus.$off("httpPost");
  }
};
</script>

<style scoped>
.suggestion-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.delete-icon-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0; /* 默认隐藏 */
  background: #f5f5f5;
  transition: opacity 0.2s, background-color 0.2s; /* 添加渐变过渡 */
}

.suggestion-item:hover .delete-icon-container {
  opacity: 1; /* 行悬停时显示删除图标背景 */
}

.delete-icon-container.hovered {
  background-color: #e0e0e0; /* 悬停在删除图标上时显示灰色背景 */
}

.my-input-popper {
  width: 100%;
}
</style>
