<template>
  <div class="login">
    <div class="login_forget">
      <el-row class="login_title">
        <el-col :span="3"
          ><div class="grid-content bg-purple" style="text-align: right; margin-right: 20px"><img src="@/assets/images/city_login_logo.png" /></div
        ></el-col>
        <el-col :span="6"
          ><div class="grid-content bg-purple-light"><h2>智售云</h2></div></el-col
        >
        <el-col :span="13"
          ><div class="grid-content bg-purple-light"><span style="font-size: 16px; color: #fff; line-height: 40px">找回密码</span></div></el-col
        >
        <el-col :span="2"
          ><div class="grid-content bg-purple-light">
            <router-link v-if="active != 2" :to="{ path: '/Login' }" style="font-size: 14px; color: #fff; text-decoration: underline"
              >返回登录</router-link
            >
          </div></el-col
        >
      </el-row>
      <div class="login_input">
        <el-row class="login_title">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <h3>1<span>确认账号</span><i class="title_right"></i></h3></div
          ></el-col>
          <el-col :span="8"
            ><div class="grid-content bg-purple-light">
              <h3 v-if="password_two">2<span>重置密码</span><i class="title_left"></i><i class="title_right"></i></h3>
              <h3 v-else class="h3_hide">2<span>重置密码</span><i class="title_left"></i><i class="title_right"></i></h3>
            </div>
          </el-col>
          <el-col :span="8"
            ><div class="grid-content bg-purple-light">
              <h3 v-if="password_three">3<span>修改完成</span><i class="title_left"></i></h3>
              <h3 v-else class="h3_hide">3<span>修改完成</span><i class="title_left"></i></h3>
            </div>
          </el-col>
        </el-row>
        <div v-if="active == 0" class="forget_step">
          <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm forget_form">
            <el-form-item label="账号" prop="loginName">
              <el-input type="text" v-model="ruleForm.loginName" autocomplete="off" placeholder="用户名/手机号/邮箱"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <div class="check_code">
                <el-input type="text" v-model="ruleForm.code" style="width: 150px" autocomplete="off" placeholder="请输入验证码"></el-input>
                <el-button type="primary" :disabled="isDisabled" class="tel_code" @click="getScanCode">{{ getCode }}</el-button>
              </div>
            </el-form-item>
          </el-form>
          <el-button type="primary" class="next_button" @click="next">下一步</el-button>
        </div>
        <div v-if="active == 1" class="forget_step">
          <el-form :model="passForm" status-icon ref="passForm" label-width="100px" class="demo-ruleForm forget_form">
            <el-form-item label="新密码" prop="password">
              <el-input type="password" v-model="passForm.password" placeholder="请输入新密码" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <div class="check_code">
                <el-input type="password" v-model="passForm.newPassword" placeholder="请再次输入新密码" @blur="pwdBlur" autocomplete="off"></el-input>
              </div>
            </el-form-item>
          </el-form>
          <el-button type="primary" class="next_button" @click="next">下一步</el-button>
        </div>
        <div v-if="active == 1" class="pass_info">
          <div id="demo">
            <p>密码至少8个字符，不允许有空格</p>
            <p>包括数字、大小写字母和特殊字符三种及以上</p>
          </div>
        </div>
        <div v-if="active == 2" class="forget_step">
          <h3>恭喜您，密码修改成功！</h3>
          <span class="forget_time" @click="next"
            ><i>{{ loginNum }}</i
            >秒后返回<router-link :to="{ path: '/login' }" style="color: #409eff; text-decoration: underline">登录</router-link>界面</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgetThePassword",
  data() {
    return {
      active: 0,
      password_two: false,
      password_three: false,
      ruleForm: {
        loginName: "",
        code: "",
        managerId: ""
      },
      passForm: {
        password: "",
        newPassword: ""
      },
      getCode: "获取验证码",
      codeNum: 120,
      isDisabled: false,
      timeNum: 10,
      loginNum: null
    };
  },
  components: {},
  methods: {
    pwdBlur() {
      if (this.passForm.password !== this.passForm.newPassword) {
        this.$message.error("两次输入密码必须相同");
        return;
      } else {
        if (this.passForm.password == "" || this.passForm.newPassword == "") {
          this.$message.error("两次输入密码不能为空");
          return;
        } else {
          if (this.passForm.password.indexOf(" ") != -1) {
            this.$message.error("密码中不能含有空格");
            return;
          }
        }
      }
      let reg =
        /^(?!^[0-9a-z]+$)(?!^[0-9A-Z]+$)(?!^[0-9\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7E]+$)(?!^[a-zA-Z]+$)(?!^[a-z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7E]+$)(?!^[A-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7E]+$)[a-z0-9A-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7E]{8,}$/;
      if (!reg.test(this.passForm.password)) {
        this.$message.error("密码至少8个字符，包括数字、大小写字母和特殊字符三种及以上");
        return;
      }
    },
    next() {
      if (this.active == 0) {
        if (this.ruleForm.loginName == "" || this.ruleForm.code == "") {
          this.$message.error("登录账号或验证码不能为空");
          return;
        }
        this.ruleForm.code = this.ruleForm.code.trim();
        const that = this;
        this.get("authority/checkForgetPwdSmsCode", {
          loginName: this.ruleForm.loginName,
          code: this.ruleForm.code
        }).then((res) => {
          if (res.errorCode == 0) {
            this.password_two = true;
            that.active++;
          } else {
            that.$message.error(res.message);
            return;
          }
        });
      }
      if (this.active == 1) {
        this.password_two = true;
        if (this.passForm.password !== this.passForm.newPassword) {
          this.$message.error("两次输入密码必须相同");
          return;
        } else {
          if (this.passForm.password == "" || this.passForm.newPassword == "") {
            this.$message.error("两次输入密码不能为空");
            return;
          } else {
            if (this.passForm.password.indexOf(" ") != -1) {
              this.$message.error("密码中不能含有空格");
              return;
            }
          }
        }
        let reg =
          /^(?!^[0-9a-z]+$)(?!^[0-9A-Z]+$)(?!^[0-9\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7E]+$)(?!^[a-zA-Z]+$)(?!^[a-z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7E]+$)(?!^[A-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7E]+$)[a-z0-9A-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7E]{8,}$/;
        if (!reg.test(this.passForm.password)) {
          this.$message.error("密码至少8个字符，包括数字、大小写字母和特殊字符三种及以上");
          return;
        }
        const that = this;
        var parma = {
          loginName: this.ruleForm.loginName,
          code: this.ruleForm.code,
          password: this.passForm.password
        };
        this.get("authority/resetPassword", parma).then((res) => {
          if (res.errorCode == 0) {
            that.active++;
            that.timeNum = 10;
            that.timeCode();
          } else {
            that.$message.error(res.message);
            return;
          }
        });
      }
      if (this.active == 2) {
        this.password_two = true;
        this.password_three = true;
      }
    },
    getScanCode() {
      const that = this;
      this.get("authority/sendForgetPwdSmsCode", { loginName: this.ruleForm.loginName }).then((res) => {
        if (res.errorCode == 0) {
          that.codeNum = 120;
          that.createCode();
          that.ruleForm.managerId = res.data.managerId;
          that.$message({
            message: "手机验证码发送成功！",
            type: "success"
          });
        } else {
          that.$message.error(res.message);
        }
      });
    },
    createCode() {
      this.isDisabled = true;
      this.getCode = "重新获取(" + this.codeNum + ")";
      this.codeNum--;
      setTimeout(() => {
        if (this.codeNum >= 0) {
          this.createCode();
        } else {
          this.isDisabled = false;
          this.getCode = "获取验证码";
        }
      }, 1000);
    },
    timeCode() {
      this.loginNum = this.timeNum;
      this.timeNum--;
      setTimeout(() => {
        if (this.timeNum >= 0) {
          this.timeCode();
        } else {
          this.$router.replace(
            {
              path: "/login"
            },
            () => {},
            () => {}
          );
        }
      }, 1000);
    },
    changeCode() {}
  },
  mounted() {},
  created() {}
};
</script>
<style scoped></style>
