//import '@babel/polyfill';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./assets/icons";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/public.css";
import "./assets/css/index.css";
import plugins from "@/plugins";
import permission from "./permission";
import VueContextMenu from "vue-contextmenu";
//Vue.config.productionTip = false
import { get, post } from "./axios/api";
import * as constants from "@/utils/constants.js";
import * as util from "./util";
import { handleTree, parseTime, resetForm } from "@/util";
import Pagination from "@/components/Pagination";
document.documentElement.style.fontSize = (window.screen.width * 100) / window.screen.width + "px";

Vue.prototype.get = get;
Vue.prototype.post = post;
Vue.prototype.util = util;
Vue.prototype.pushQuery = util.pushQuery;
Vue.prototype.pullQuery = util.pullQuery;
Vue.prototype.getQuery = util.getQuery;
Vue.prototype.handleTree = handleTree;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.$constants = constants;
const myMixin = {
  computed: {
    isAdmin() {
      return permission.isAdmin();
    },
    isMain() {
      return permission.isMain();
    },
    isChild() {
      return permission.isChild();
    },
    isExtra() {
      return permission.isExtra();
    }
  },
  methods: {
    hasPermit(path) {
      // 检查权限逻辑
      return permission.hasPermit(path);
    }
  }
};
Vue.mixin(myMixin);

Vue.component("Pagination", Pagination);

Vue.use(ElementUI, VueContextMenu);
Vue.use(plugins);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
