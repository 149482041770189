<template>
  <router-view />
</template>

<script>
export default {
  name: "Main",
  data() {
    return {};
  },
  watch: {},
  components: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style scoped></style>
